import React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Article,
  Content,
  Header,
  Layout,
  Pagination,
  SectionTitle,
  SEO,
  Wrapper,
} from '../components';
import { Helmet } from 'react-helmet';
import config from '../../config/SiteConfig';
import Data from '../models/Data';
import Post from '../models/Post';

interface Props {
  data: Data;
  pageContext: {
    currentPage: number;
    totalPages: number;
  };
}

export default (props: Props) => {
  const { currentPage, totalPages } = props.pageContext;
  const dummyPostForSEO: Post = {
    content: '',
    createdAt: '',
    description: '',
    slug: '',
    status: false,
    title: '',
    strapiId: false,
    publishedAt: '',
    bannerImage: '',
    image: false,
    authors: false,
    editors: false,
  };
  const { data } = props;
  const { edges, totalCount } = data.allStrapiArticle;

  return (
    <Layout>
      <Helmet title={`Blog | ${config.siteTitle}`} />
      <SEO postPath="/" postNode={dummyPostForSEO} postSEO={false} curPath="blogs" />
      <Header>
        <Link to="/">{config.siteTitle}</Link>
        <SectionTitle uppercase={true}>Latest stories ({totalCount})</SectionTitle>
      </Header>
      <Wrapper>
        <Content>
          {edges.map((post) => (
            <Article
              title={post.node.title}
              date={post.node.publishedAt}
              excerpt={post.node.description}
              slug={post.node.slug}
              key={post.node.strapiId}
              thumbnail={post.node.thumbnail}
            />
          ))}
          <Pagination currentPage={currentPage} totalPages={totalPages} url={'blogs'} />
        </Content>
      </Wrapper>
    </Layout>
  );
};

export const BlogQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allStrapiArticle(sort: { fields: publishedAt, order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          content
          createdAt
          description
          slug
          status
          title
          strapiId
          publishedAt
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 300, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;
